import React from "react";

const Dropdown = (props) => {
  const {
    id,
    type,
    itemvalues,
    labelName,
    isDefault,
    defaultLayout,
    activeFlag,
  } = props;

  console.log("tesst-----------",itemvalues);
  return (
    <>
      {type === "dropdown" && (
        <>
          <label
            className="biglabelText"
            htmlFor={id}
            draggable={isDefault && defaultLayout ? "false" : null}
          >
            {labelName}
          </label>
          <select
            className="MainDropdown"
            draggable={isDefault && defaultLayout ? "false" : null}
            disabled={activeFlag ? false : true}
          >
            <option>--Select an Option--</option>
            {itemvalues.map((optionName, i) => (
              <option value={optionName} key={i}>
                {optionName.value}
              </option>
            ))}
          </select>
        </>
      )}
    </>
  );
};

export default Dropdown;
