import React, { useEffect, useMemo, useRef, useState } from 'react';
import './workflow.scss';
import Mainlogo from './assets/images/logo.svg';
import AddCheckbox from './assets/images/add_chechbox.svg';
import RemoveCheckbox from './assets/images/remove_checkbox.svg';
import LogoutIcon from './assets/images/logout_icon.svg';
import Screenfull from './assets/images/screenfull.svg';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import infoIcon from '../../assets/images/infoIcon.svg';
import brandlogo from '../../assets/images/brand_logo.svg';
import { Post, Get, Put } from '../../_common/httpService';
import SpinnerIcon from '../../assets/images/fa_spinner.svg';
import { useStateValue } from '../../StateProvider/StateProvider';
import copyIcon from '../../assets/images/copyIcon.png';
import AddCircleoutline from '../../assets/images/addCircleoutline.svg';
import { useFormik } from 'formik';
import SearchInput from '../../CommonComponent/searchInput/autoCompleteInputComponent';
import EyetrackingIcon from '../../assets/images/eye_tracking_icon.svg'; 
import EyetrackingIconWhite from '../../assets/images/eye_tracking_icon_white.svg';

const Workflow = () => {
  const [{ savingTxt, isSaving, loanCount }, dispatch] = useStateValue();
  const { workflowID } = useParams();
  const layoutId = sessionStorage.getItem('layout_Id');
  const personaId = sessionStorage.getItem('personaId');
  const def_wf = JSON.parse(sessionStorage.getItem('default_wf'));
  const lname = sessionStorage.getItem('lname');
  const navigate = useNavigate();
  const [appsAndActions, setAppsAndActions] = useState([]);
  const [newflowshow, setnewflowShow] = useState(false);
  const NewflowhandleClose = () => setnewflowShow(false);
  const [steps, setSteps] = useState({});
  const [configs, setConfigs] = useState([]);
  const [workflowId, setWorkflowId] = useState(null);
  const [layId, setLayId] = useState(null);
  const [noAction, setNoAction] = useState({
    index: null,
    action: '',
    subActionInd: null,
    status: false,
  });
  const [showInfoIcon, setShowInfoIcon] = useState(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [disableSaveBtn, setdisableSaveBtn] = useState(true);
  const [saveBtnTxt, setSaveBtnTxt] = useState('Save');
  const [actions, setActions] = useState([]);
  const [actionCheckbox, setActionCheckbox] = useState({});
  const [tooltipHover, setToolTipHover] = useState(false);
  const [tooltipSuccess, setToolTipSuccess] = useState(false);
  const [embedUrl, setEmbedUrl] = useState('');
  const [justDisableStep, setJustDisableStep] = useState(true);

  // ---------- workflow dropdown states ----------
  const [cusDDTwoMouseEnter, setCusDDTwoMouseEnter] = useState(false);
  const [customdropdownTwo, setCustomdropdowntwo] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState({
    placeholder: '',
    id: '',
  });
  const handleAddWorkflowShow = () => setAddWorkflowShow(true);
  const [AddWorkflowShow, setAddWorkflowShow] = useState(false);
  const [btnTxt, setBtnTxt] = useState('Create');
  const [disableBtn, setDisableBtn] = useState(false);
  const [existLayName, setExistLayName] = useState({
    isShow: false,
    message: '',
  });
  let isDefaultLayout = sessionStorage.getItem('isDefault');
  const [allWorkflows, setAllWorkflows] = useState([]);
  let persona_id = sessionStorage.getItem('personaId');
  const [workflowList, setWorkflowList] = useState([]);
  const [enableToggleBtn, setEnableToggleBtn] = useState(true);

  const getLoanCount = async () => {
    try {
      let res = await Get(`layout/loancount/${layoutId}`);
      if (res.statusCode === 200) {
        dispatch({
          type: "SET_LOANCOUNT",
          payload: +res.loanCount,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLoanCount();
  }, [])

  const constructActionConditions = (data) => {
    const conditions = {};
    const newActionCheckbox = { ...actionCheckbox };
    data.map((condition) => {
      conditions[condition.section] = {};
      condition.content.map((content) => {
        conditions[condition.section][content.service_name] = [];
        content.actions.map((action) => {
          if (
            newActionCheckbox[
              `action_checkbox_${action.action_set_id}_${content.service_name}`
            ] !== true
          ) {
            newActionCheckbox[
              `action_checkbox_${action.action_set_id}_${content.service_name}`
            ] = action.is_selected ? true : false;
          }
          conditions[condition.section][content.service_name].push({
            action_id: action.action_id,
            action_set_id: action.action_set_id,
            checked: action.is_selected ? true : false,
            disabled: !action.active_flag || def_wf ? true : false,
          });
        });
      });
    });
    setActionCheckbox(newActionCheckbox);
    setActions(conditions);
  };

  const handleAddWorkflowClose = () => {
    setCustomdropdowntwo(false);
    setAddWorkflowShow(false);
  };

  const cursorEnterTwo = () => {
    setCusDDTwoMouseEnter(true);
  };

  const cursorOutTwo = () => {
    setCusDDTwoMouseEnter(false);
  };

  const handleSelectWorkflow = (wf) => {
    navigate(`/workflow/${wf?.id}`);
    // allWorkflows?.map((ele) => {
    //   ele.id === wf?.id && setSelectedWorkflow({placeholder: wf?.workflow_name, id: wf?.id })
    // })
    window.location.reload();
  };

  const getAllWorkflows = async () => {
    try {
      let resp = await Get(`workflow/allworkflows/${layoutId}`);
      if (resp.statusCode === 200) {
        setAllWorkflows(resp.data);
        if (resp.data.length > 0) {
          resp?.data?.map((ele) => {
            if (ele.id === workflowID) {
              setSelectedWorkflow({
                placeholder: ele.workflow_name,
                id: ele?.id,
              });
            }
          });
        } else {
          setSelectedWorkflow({
            placeholder: 'No workflow found',
            id: '',
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWorkflowList = async () => {
    try {
      let res = await Get(`workflow/all/${persona_id}`);
      setWorkflowList(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    layoutId && getAllWorkflows();
    getWorkflowList();
  }, [layoutId]);

  const clone_workflow = async (body) => {
    let resp = await Post('workflow/clone-workflow', body);
    return resp;
  };

  const cloneWorkflowFormik = useFormik({
    initialValues: {
      workflow_name: '',
      clone_from: '',
    },
    validate: (values) => {
      const errors = {};

      if (!values.workflow_name) {
        errors.workflow_name = 'Workflow Name is required';
      }
      // if (!values.layoutId) {
      //   errors.layoutId = "Layout Id is required";
      //   console.log("ERROR", errors.layoutId, "LayoutId", values.layoutId);
      // }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      setBtnTxt('Cloning Workflow...');
      setDisableBtn(true);
      if (!values.clone_from) values.clone_from = workflowList[0]?.workflow_id;
      try {
        console.log(isDefaultLayout);
        // if (isDefaultLayout === false) {
        const postBody = {
          cloned_from: values.clone_from,
          workflow_name: values.workflow_name,
          layoutId: layoutId,
        };
        let res = await clone_workflow(postBody);
        if (res.statusCode === 200) {
          resetForm();
          getAllWorkflows();
          handleAddWorkflowClose();
          setCustomdropdowntwo(!customdropdownTwo);
          setDisableBtn(false);
          setBtnTxt('Create');
          navigate(`/workflow/${res?.data}`);
        } else if (res.statusCode === 400) {
          setBtnTxt('Create');
          setExistLayName({ isShow: true, message: res.message });
          setDisableBtn(false);
          setTimeout(() => {
            setExistLayName({ isShow: false, message: '' });
          }, 2000);
        } else {
          setDisableBtn(false);
        }
        // } else {
        //   setBtnTxt("Create");
        //   setExistLayName({
        //     isShow: true,
        //     message: "Cannot create workflow for the Default Layout",
        //   });
        //   setDisableBtn(false);
        //   setTimeout(() => {
        //     setExistLayName({ isShow: false, message: "" });
        //   }, 2000);
        // }
      } catch (error) {
        setDisableBtn(false);
        setBtnTxt('Create');
        console.log(error);
      }
    },
  });

  const getSelectedConfigs = async () => {
    try {
      let resp = await Get(`workflow/selected/${workflowId}`);
      if (resp.statusCode === 200) {
        setConfigs(resp?.data?.configs);
      }
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const getWorkFLowURL = async () => {
    try {
      let resp = await Get(`workflow/getWorkFlowUrl/${workflowID}`);
      if (resp?.statusCode == 200) {
        console.log('resp', resp?.data);
        // embedUrl.current.value = resp?.data;
        setEmbedUrl(`${resp?.data}/create-account`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // cloneWorkflow();
    getAppsAndActions();
    getWorkFLowURL();
  }, []);

  useEffect(() => {
    if (workflowId) {
      getSelectedConfigs();
    }
  }, [workflowId, workflowID]);

  const getAppsAndActions = async () => {
    try {
      let resp = await Get(`workflow/getworkflowconfigs/${workflowID}`);
      if (resp.statusCode === 200) {
        setAppsAndActions([]);
        setAppsAndActions(resp?.data);
        sessionStorage.setItem('default_wf', resp?.isDefault_workflow);
        let userOnboarding = resp?.data.filter((e) => e.order_no === 1);
        setSteps(JSON.parse(userOnboarding[0]?.content[0]?.steps));
        setWorkflowId(resp?.data[0]?.content[0]?.wf_id);
        setLayId(resp?.data[0]?.content[0]?.layout_id);
        constructActionConditions(resp?.data);
      }
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisableStep = async (wf_id, s_id, f_name) => {
    if(loanCount === 0) {
      try {
        if (!def_wf) {
          if (f_name !== 'Bank Lookup' && s_id !== 4) {
            setEnableToggleBtn(false)
            dispatch({
              type: 'SET_SAVE',
              item: true,
            });
            let res = justDisableStep === false && await handleSave();
            if(justDisableStep === false ? res.statusCode === 200 : true) {
              let resp = await Put(`workflow/toggleworkflow/${s_id}/${wf_id}`);
              if (resp.statusCode === 200) {
                const resOfAppsActions = await getAppsAndActions();
                const resOfConfigs = await getSelectedConfigs();
                dispatch({
                  type: "SET_SAVE",
                  item: false,
                });
                setdisableSaveBtn(false);
                
                if(resOfAppsActions && resOfConfigs ) {
                  setEnableToggleBtn(true);
                }
              }
            }
          }
        }
      } catch (error) {
        dispatch({
          type: 'SET_SAVE',
          item: false,
        });
        console.log(error);
      }
    } else {
      setShowInfoIcon(true);
      setTimeout(() => {
        setShowInfoIcon(false);
        setShowInfoTooltip(false);
      }, 5000)
    }
  };

  const handleStepUserOnboard = (e) => {
    // if (e.target.value === "user_onboarding") {
    //   let obj = steps;
    //   obj.apply_now_from_landingPage.is_selected = e.target.checked;
    //   if (!e.target.checked) {
    //     obj.apply_now_from_landingPage.default_landingPage = e.target.checked;
    //     obj.apply_now_from_landingPage.embed_code = e.target.checked;
    //     document.querySelector("#Embed").checked = false;
    //     document.querySelector("#Default_lp").checked = false;
    //   }
    //   setSteps({ ...obj });
    // }
    if(loanCount === 0) {
      if (!def_wf) {
        if (e.target.value === 'Invite_lenderPortal') {
          let obj = steps;
          obj.invite_from_lender_portal.is_selected = e.target.checked;
          setSteps({ ...obj });
        } else if (e.target.value === 'embed_code') {
          let obj = steps;
          obj.apply_now_from_landingPage.embed_code = e.target.checked;
          obj.apply_now_from_landingPage.default_landingPage = !e.target.checked;
          setSteps({ ...obj });
        } else if (e.target.value === 'default_landingPage') {
          let obj = steps;
          obj.apply_now_from_landingPage.default_landingPage = e.target.checked;
          obj.apply_now_from_landingPage.embed_code = !e.target.checked;
          setSteps({ ...obj });
        }
        setdisableSaveBtn(false);
      }
    } else {
      setShowInfoIcon(true);
      setTimeout(() => {
        setShowInfoIcon(false);
        setShowInfoTooltip(false);
      },5000)
    }
  };

  const handleActionsUseronboard = (e, s_id, f_id, acts_id, act_set_id) => {
    if(loanCount === 0) {
      if (!def_wf) {
        let exiObj = configs?.filter((ele) => ele.service_id === s_id);
        if (exiObj.length > 0) {
          let exiActs = exiObj[0].action_ids.some((el) => el[act_set_id]);
          // console.log("exiActs::: ", exiActs);
          if (exiActs) {
            let findInd = exiObj[0].action_ids.findIndex(
              (obj) => obj[act_set_id]
            );
            exiObj[0].action_ids.splice(findInd, 1);
            // exiObj[0].action_ids = [...exiObj[0].action_ids, {[act_set_id]: acts_id}]
          } else {
            exiObj[0].action_ids = [
              ...exiObj[0].action_ids,
              { [act_set_id]: acts_id },
            ];
          }
          // console.log("configs::: ", configs);
        } else {
          let obj = {
            service_id: s_id,
            frame_id: f_id,
            apps_ids: [],
            action_ids: [],
          };
          // obj.apps_ids = [...obj.apps_ids, apps_id];
          obj.action_ids = [...obj.action_ids, { [act_set_id]: acts_id }];
          setConfigs([...configs, { ...obj }]);
        }
        setdisableSaveBtn(false);
      }
    } else {
      setShowInfoIcon(true);
      setTimeout(() => {
        setShowInfoIcon(false);
        setShowInfoTooltip(false);
      }, 5000)
    }
  };

  const handleActions = (e, s_id, f_id, acts_id, act_set_id) => {
    if(loanCount === 0) {
      if (!def_wf) {
        let exiObj = configs?.filter((ele) => ele.service_id === s_id);
        if (exiObj.length > 0) {
          let exiActs = exiObj[0].action_ids.some((el) => el[act_set_id]);
          if (exiActs) {
            let findInd = exiObj[0].action_ids.findIndex(
              (obj) => obj[act_set_id]
            );
            exiObj[0].action_ids.splice(findInd, 1);
            exiObj[0].action_ids = [
              ...exiObj[0].action_ids,
              { [act_set_id]: acts_id },
            ];
          } else {
            exiObj[0].action_ids = [
              ...exiObj[0].action_ids,
              { [act_set_id]: acts_id },
            ];
          }
          // console.log("configs::: ", configs);
        } else {
          let obj = {
            service_id: s_id,
            frame_id: f_id,
            apps_ids: [],
            action_ids: [],
          };
          // obj.apps_ids = [...obj.apps_ids, apps_id];
          obj.action_ids = [...obj.action_ids, { [act_set_id]: acts_id }];
          setConfigs([...configs, { ...obj }]);
        }
        setJustDisableStep(false);
        setdisableSaveBtn(false);
      }
    } else {
      setShowInfoIcon(true);
      setTimeout(() => {
        setShowInfoIcon(false);
        setShowInfoTooltip(false);
      }, 5000)
    }
  };

  const removeAction = (s_id, act_set_id) => {
    if(loanCount === 0) {
      if (!def_wf) {
        let exiObj = configs?.filter((ele) => ele.service_id === s_id);
        if (exiObj.length > 0) {
          let exiActs = exiObj[0].action_ids.some((el) => el[act_set_id]);
          if (exiActs) {
            let findInd = exiObj[0].action_ids.findIndex(
              (obj) => obj[act_set_id]
            );
            exiObj[0].action_ids.splice(findInd, 1);
          }
          // console.log('configs::: ', configs);
        }
        setdisableSaveBtn(false);
      }
    } else {
      setShowInfoIcon(true);
      setTimeout(() => {
        setShowInfoIcon(false);
        setShowInfoTooltip(false);
      }, 5000)
    }
  };

  // console.log('configs::: ', configs);
  // console.log('appsAndActions::: ', appsAndActions);

  const handleApps = (e, s_id, f_id, apps_id, wf_id, s_name, r_no, f_name) => {
    if(loanCount === 0) {
      if (!def_wf) {
        let exiObj = configs?.filter((ele) => ele.service_id === s_id);
        let tempArr = [...appsAndActions];
  
        if (exiObj?.length > 0) {
          // when apps are radio button
          // exiObj[0].apps_ids = [];
          // exiObj[0].apps_ids = [apps_id];
          
          if (
            exiObj[0].apps_ids?.length === 1 &&
            exiObj[0].apps_ids[0] === apps_id
          ) {
            if(f_name !== "Bank Lookup") {
              let required_set = tempArr?.filter(el => el.order_no === r_no);
              let updatedContent = required_set[0].content.map((con) => con.service_id == s_id
                ? {...con, apps: con.apps.map((a)=> a.app_id == apps_id ? {...a, is_selected: a.is_selected ? false : true} : a)}
                  :con
              )
              let updatedAppsActions = tempArr.map((ele) => ele.order_no === r_no ? {...ele, content: [...updatedContent]} : ele)
              setAppsAndActions(updatedAppsActions)
            }
          
            // CALLING DISABLE STEP FUNC
            let exiApps = exiObj[0].apps_ids.some((el) => el === apps_id);
            if (exiApps) {
              handleDisableStep(wf_id, s_id, f_name);
            }
          } else {
  
            let required_set = tempArr?.filter(el => el.order_no === r_no);
            let updatedContent = required_set[0].content.map((con) => con.service_id == s_id
               ? {...con, apps: con.apps.map((a)=> a.app_id == apps_id ? {...a, is_selected: a.is_selected ? false : true} : a)}
                :con
            )
            let updatedAppsActions = tempArr.map((ele) => ele.order_no === r_no ? {...ele, content: [...updatedContent]} : ele)
            setAppsAndActions(updatedAppsActions)
  
            // when apps are checkbox
            let exiApps = exiObj[0].apps_ids.some((el) => el === apps_id);
            if (exiApps) {
              let findInd = exiObj[0].apps_ids.indexOf(apps_id);
              exiObj[0].apps_ids.splice(findInd, 1);
            } else {
              exiObj[0].apps_ids = [...exiObj[0].apps_ids, apps_id];
            }
          }
        } else {
          let obj = {
            service_id: s_id,
            frame_id: f_id,
            apps_ids: [],
            action_ids: [],
          };
          obj.apps_ids = [...obj.apps_ids, apps_id];
          setConfigs([...configs, { ...obj }]);
        }
        setJustDisableStep(false);
        setdisableSaveBtn(false);
      }
    } else {
      setShowInfoIcon(true);
      setTimeout(() => {
        setShowInfoIcon(false);
        setShowInfoTooltip(false);
      }, 5000)
    }
  };

  const handleSave = async () => {
    if(loanCount === 0) {
      if (!def_wf) {
        try {
          setdisableSaveBtn(true);
          setEnableToggleBtn(false);
          dispatch({
            type: 'SET_SAVINGTXT',
            payload: 'Saving ...',
          });
          dispatch({
            type: 'SET_SAVE',
            item: true,
          });
          let tempConf = [...configs];
          tempConf.map((ele) => {
            let b = ele.action_ids.map((el) => +Object.values(el).toString());
            return (ele.action_ids = b);
          });
          tempConf.map((ele) =>
          ele.service_id === 8 ? (ele.steps = steps) : (ele.steps = null)
          );
          // console.log('tempConf::: ', tempConf);
          const body = {
            workflow_id: workflowId,
            layout_id: layId,
            configs: tempConf,
          };
          let resp = await Post('workflow/updateconfiguration', body);
          if (resp.statusCode === 200) {
            dispatch({
              type: 'SET_SAVE',
              item: false,
            });
            setSaveBtnTxt('Saved');
            const resOfAppsActions = await getAppsAndActions();
            setdisableSaveBtn(true);
            const resOfConfigs = await getSelectedConfigs();
            setTimeout(() => {
              setSaveBtnTxt('Save');
            }, 2000);
            setJustDisableStep(true);
            if(resOfAppsActions && resOfConfigs) {
              setEnableToggleBtn(true);
            }
          }
          return resp;
        } catch (error) {
          setdisableSaveBtn(true);
          setEnableToggleBtn(true);
          dispatch({
            type: 'SET_SAVE',
            item: false,
          });
          console.log(error);
        }
      }
    } else {
      setShowInfoIcon(true);
      setTimeout(() => {
        setShowInfoIcon(false);
        setShowInfoTooltip(false);
      }, 5000)
    }
  };

  return (
    <>
      {/* <div className="workflow"> */}
      <div className="topHeader">
        <div className="display_Flex spaceBetween alignItem_center w100per">
          <div className="logosection headerinherit">
            <div className="displayFlex">
              {/* <img src={menuicon} className="mobileMenuicon" /> */}
              <div className="brand_logo">
                <img src={brandlogo} alt='brandlogo' />
              </div>
            </div>
          </div>

          {/* workflow Dropdown */}
          <div className="positionRelative">
            <input
              onBlur={() => !cusDDTwoMouseEnter && setCustomdropdowntwo(false)}
              readOnly
              className="dropdownSelect_input"
              placeholder={selectedWorkflow.placeholder}
              onClick={() => setCustomdropdowntwo(!customdropdownTwo)}
            />
            {customdropdownTwo && (
              <div
                className="dropdownResult"
                onMouseEnter={cursorEnterTwo}
                onMouseLeave={cursorOutTwo}
                style={{ maxHeight: '300px' }}
              >
                <div
                  className="dropdownResultItem"
                  onClick={handleAddWorkflowShow}
                >
                  <button className="emptyButton displayFlex AlignitemCenter gap_12">
                    <img src={AddCircleoutline} className="width20 democratFilter"/>
                    <span className="fontWeight_500 fontsize_12 sunsetOrangeText">
                      Add New Workflow
                    </span>
                  </button>
                </div>

                {/* Workflow Modal */}
                <Modal
                  show={AddWorkflowShow}
                  onHide={handleAddWorkflowClose}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="fontsize16">
                      Add Workflow
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={cloneWorkflowFormik.handleSubmit}>
                      <div className="mb_12">
                        <label className="biglabelText mb_8">
                          Workflow Name <span className="requiredText">*</span>
                        </label>
                        <input
                          type="text"
                          transform={(value) => value.trim()}
                          name="workflow_name"
                          values={cloneWorkflowFormik.values.workflow_name}
                          className="MainInput"
                          placeholder="Enter Workflow Name"
                          onChange={cloneWorkflowFormik.handleChange}
                        />
                        <div
                          style={{ marginTop: '5px' }}
                          className="displayFlex AlignitemCenter justifyContent_spaceBetween"
                        >
                          {cloneWorkflowFormik.errors.workflow_name && (
                            <span className="errorText">
                              {cloneWorkflowFormik.errors.workflow_name}
                            </span>
                          )}
                          {existLayName.isShow && (
                            <span className="errorText">
                              {existLayName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <label className="biglabelText mb_8">
                        Clone From <span className="requiredText">*</span>
                      </label>
                      <div className="custom_cloneFrom">
                        <SearchInput
                          name="workflow_name"
                          options={workflowList}
                          styles={{
                            marginBottom: '40px !important',
                            width: '100px',
                          }}
                          initialValue={workflowList[0]?.workflow_name}
                          // defaultValue={isdefault?.layout_name}
                          placeholder={workflowList[0]?.workflow_name}
                          getOptionLabel={(option) => option?.workflow_name}
                          getOptionValue={(option) => option?.workflow_name}
                          handleChange={(value) => {
                            cloneWorkflowFormik.setValues({
                              ...cloneWorkflowFormik.values,
                              clone_from: value.workflow_id,
                              layoutId: layId,
                            });
                          }}
                        />
                      </div>
                      {cloneWorkflowFormik.errors.clone_from && (
                        <span className="errorText">
                          {cloneWorkflowFormik.errors.clone_from}
                        </span>
                      )}
                      <button
                        disabled={disableBtn}
                        // style={disableBtn ? { opacity: 0.5 } : { opacity: 1 }}
                        type="submit"
                        className="MainButton mt20px"
                      >
                        {btnTxt}
                      </button>
                    </form>
                  </Modal.Body>
                </Modal>
                {allWorkflows?.map((wf, ind) => (
                  <div
                    onClick={() => setCustomdropdowntwo(false)}
                    style={
                      workflowID === wf?.id
                        ? { backgroundColor: '#04A9F5', color: '#fff' }
                        : { backgroundColor: '' }
                    }
                    className="dropdownResultItem displayFlex AlignitemCenter justifyContent_spaceBetween"
                    key={ind}
                  >
                    <div className="fontWeight_500 fontsize_12">
                      {wf?.workflow_name}
                    </div>
                    <div>
                      <button
                        style={
                          selectedWorkflow.id === wf?.id
                            ? { backgroundColor: '#fff', color: '#04A9F5' }
                            : { backgroundColor: '#04A9F5', color: '#fff' }
                        }
                        onClick={() => handleSelectWorkflow(wf)}
                        
                      >
                        {/* <img src={selectedWorkflow.id === wf?.id ? EyetrackingIcon : EyetrackingIconWhite} /> */}
                        <img className={selectedWorkflow.id === wf?.id
                          ? "democratFilter" : null} 
                          src={
                            selectedWorkflow.id === wf?.id
                              ? EyetrackingIcon
                              : EyetrackingIconWhite
                          }
                        />
                        &nbsp; View
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="display_Flex alignItem_center gap20 mr20px">
            <div style={{ marginRight: '15px' }}>
            {(loanCount > 0 || showInfoIcon) && (
              <div>
                {showInfoIcon && (
                  <>
                    <div className="infoiconBlock iconanimate2"></div>
                    <div
                      className="infoiconBlock2"
                      onClick={() => setShowInfoTooltip(!showInfoTooltip)}
                    >
                      <img src={infoIcon} alt="InfoIcon" />
                    </div>
                  </>
                )}
                {showInfoTooltip && (
                  <div className="infoPopup">
                    Loan already associated with this layout. So, You can't make
                    any changes in this layout
                  </div>
                )}
              </div>
            )}
            </div>
            <div>
              {isSaving ? (
                <button className="saving_spin ml_24">
                  <img src={SpinnerIcon} /> <span>{savingTxt}</span>
                </button>
              ) : null}
            </div>
            <div>
              <button
                className="Mainbutton goldenGlow_secondarybtnClr"
                onClick={() => navigate(`/form-builder/${personaId}`)}
              >
                <span>Back</span>
              </button>
            </div>
            <div style={disableSaveBtn ? { opacity: 0.3 } : { opacity: 1 }}>
              <button
                disabled={def_wf || disableSaveBtn}
                className="Mainbutton goldenGlow_secondarybtnClr"
                onClick={handleSave}
              >
                {/* <img src={AddIcon} alt="AddIcon" /> */}
                <span>{saveBtnTxt}</span>
              </button>
            </div>
            {/* <div style={disablePubBtn ? {opacity: .3} : {opacity: 1}}>
            <button disabled={disablePubBtn}
              className="MainBtn goldenGlow_secondarybtnClr"
              onClick={html_convertion}
            >
              <span>Publish</span>
            </button>
            </div> */}
            {/* <div>
            <button
              onClick={() => navigate("/form-builder")}
              className="MainBtn goldenGlow_primarybtnClr"
            >
              <img className="whiteFilter" src={LogoutIcon} alt="LogoutIcon" />
              <span> Log Out</span>
            </button>
          </div> */}
          </div>
        </div>
      </div>

      <Modal
        show={newflowshow}
        onHide={NewflowhandleClose}
        centered
        className="smallpopup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h5 className="fontSize14 fontWeight500 blackGlaze_text mb24">
            Are You Sure ?? You want to create new flow?
          </h5>

          <div className="display_Flex alignItem_center gap12 justifyContentEnd">
            <div>
              <button className="MainBtn goldenGlow_secondarybtnClr">
                Cancel
              </button>
            </div>
            <div>
              <button className="MainBtn goldenGlow_primarybtnClr">ok</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="contentWrapper">
        {/* <div className='sidebar'> */}
        {/* 1st one start */}
        {/* <div className="StepFrame gap22">
          <div className="itemCol display_Flex gap32 right_dashedBorder bottom_dashedBorder">
            <div className="square">1</div>

            <div>
              <h2 className="fontSize24 fontWeight600 blackGlaze_text">
                User Onboarding
              </h2>
              <p className="steelWool_text fontSize12 fontWeight500 mb24">
                Choose options here
              </p>

              <button className="MainBtn blackGlaze_secondarybtnClr">
                <img src={Screenfull} alt="Screenfull" />
                <span>Preview</span>
              </button>
            </div>
          </div>

          <div className="itemCol">
            <div className="mainFrame mb_20">
              <label className="goldenGlow_Frame">
                <div>
                  <p className="nero_text fontSize16 fontWeight500">
                    “Apply Now” from Lender Provider Landing Page
                  </p>
                </div>

                <div className="accordionCheckbox ">
                  <input
                    type="checkbox"
                    id="provider_lp"
                    name="user_onboarding"
                  />

                  <label htmlFor="provider_lp"></label>
                </div>
              </label>

              <div className="ptlr_20">
                <div>
                  <label
                    htmlFor="Default_lp"
                    className="radioInput mb_12 fontSize14"
                  >
                    <input type="radio" id="Default_lp" name="time" />
                    Default Landing Page
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="Embed_caccount"
                    className="radioInput fontSize14"
                  >
                    <input type="radio" id="Embed_caccount" name="time" />
                    Embed ‘Create Acc’ Widget
                  </label>
                </div>
              </div>
            </div>

            <div className="mainFrame">
              <label className="goldenGlow_Frame">
                <div>
                  <p className="nero_text fontSize16 fontWeight500">
                    Invite from Lender Portal
                  </p>
                </div>

                <div className="accordionCheckbox ">
                  <input
                    type="checkbox"
                    id="Invite_lenderPortal"
                    name="user_onboarding"
                  />

                  <label htmlFor="Invite_lenderPortal"></label>
                </div>
              </label>
            </div>
          </div>

          <div className="itemCol">
            <div className="actionFrame p16">
              <div className="mb24">
                <h3 className="fontSize20 fontWeight500 blackGlaze_text">
                  Actions
                </h3>
                <p className="steelWool_text fontSize12">
                  You can select or deselect Onboarding Actions here.
                </p>
              </div>

              <div className="action_Checkbox mb24">
                <input type="checkbox" id="account_Creation1" />

                <label
                  htmlFor="account_Creation1"
                  className="customLabelrightm16"
                >
                  {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}Successful {">"}{" "}
                  Account Creation
                </label>
              </div>

              <div className="action_Checkbox mb24">
                <input type="checkbox" id="account_Creation2" />

                <label
                  htmlFor="account_Creation2"
                  className="customLabelrightm16"
                >
                  {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}Successful {">"}{" "}
                  Account Creation
                </label>
              </div>
              <div className="action_Checkbox mb24">
                <input type="checkbox" id="account_Creation3" />

                <label
                  htmlFor="account_Creation3"
                  className="customLabelrightm16"
                >
                  {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}Successful {">"}{" "}
                  Account Creation
                </label>
              </div>
              <div className="action_Checkbox mb24">
                <input type="checkbox" id="account_Creation4" />

                <label
                  htmlFor="account_Creation4"
                  className="customLabelrightm16"
                >
                  {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}Successful {">"}{" "}
                  Account Creation
                </label>
              </div>

              <button
                className="blackGlaze_primarybtnClr MainBtn"
                onClick={addActionhandleShow}
              >
                Add Action
              </button>
              <Modal
                show={addActionshow}
                onHide={addActionhandleClose}
                centered
                className="addAction"
              >
                <Modal.Header closeButton>
                  <h2 className="fontSize16 fontWeight500 blackGlaze_text">
                    Add Action
                  </h2>
                </Modal.Header>
                <Modal.Body>
                  <div className="mb24 positionRelative">
                    <input
                      className="InputselectDropdown"
                      placeholder="--Select--"
                      onClick={() => setLog(!log)}
                    />
                    {log && (
                      <div className="searchResults">
                        <div className="searchResultsItem">
                          <label className="radioInput fontSize12">
                            <input type="radio" id="Default_lp" />
                            {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}
                            Successful {">"} Account Creation
                          </label>

                          <label className="radioInput fontSize12">
                            <input type="radio" id="Default_lp" />
                            {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}
                            Successful {">"} Account Creation
                          </label>
                        </div>

                        <div className="searchResultsItem">
                          <label className="radioInput fontSize12">
                            <input type="radio" id="Default_lp" />
                            {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}
                            Successful {">"} Account Creation
                          </label>

                          <label className="radioInput fontSize12">
                            <input type="radio" id="Default_lp" />
                            {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}
                            Successful {">"} Account Creation
                          </label>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="display_Flex alignItem_center gap12 justifyContentEnd">
                    <div>
                      <button className="MainBtn goldenGlow_secondarybtnClr">
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button className="MainBtn goldenGlow_primarybtnClr">
                        ok
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div> */}
        {/* 1st one end

        {/* 2nd  start */}
        {appsAndActions?.map((ele, i) => (
          <React.Fragment key={i}>
            {ele?.order_no == 1 ? (
              <div className="StepFrame gap22">
                <div className="itemCol display_Flex gap32 right_dashedBorder bottom_dashedBorder">
                  <div className="square">{ele?.order_no}</div>

                  <div>
                    <h2 className="fontSize20 fontWeight600 blackGlaze_text">
                      {ele?.section}
                    </h2>
                    <p className="steelWool_text fontSize12 fontWeight500">
                      Choose Your Onboarding Flow
                    </p>

                    {/* <button className="MainBtn blackGlaze_secondarybtnClr" data-cooltipz-dir="top"
                      aria-label="Preview">
                      <img src={Screenfull} alt="Screenfull" />
                      <span>Preview</span>
                    </button> */}
                  </div>
                </div>

                <div className="itemCol">
                  <div className="mainFrame mb_20 ">
                    <label className="goldenGlow_Frame">
                      <div>
                        <p className="nero_text fontSize12 fontWeight500">
                          “Apply Now” from Brand Page
                        </p>
                      </div>

                      <div className="accordionCheckbox">
                        <input
                          readOnly
                          disabled={def_wf}
                          // onChange={(e) => handleStepUserOnboard(e)}
                          type="checkbox"
                          id="provider_lp"
                          name="user_onboarding"
                          value="user_onboarding"
                          checked={
                            JSON.parse(ele?.content[0]?.steps)
                              ?.apply_now_from_landingPage?.is_selected
                          }
                        />

                        <label htmlFor="provider_lp"></label>
                      </div>
                    </label>

                    <div className="p20">
                      <div>
                        <label
                          htmlFor="Default_lp"
                          className="radioInput mb_12 fontSize12"
                        >
                          <input
                            onChange={(e) => handleStepUserOnboard(e)}
                            type="radio"
                            id="Default_lp"
                            name="default_landingPage"
                            value="default_landingPage"
                            disabled={
                              steps?.apply_now_from_landingPage?.is_selected &&
                              !def_wf
                                ? false
                                : true
                            }
                            defaultChecked={
                              JSON.parse(ele?.content[0]?.steps)
                                ?.apply_now_from_landingPage
                                ?.default_landingPage
                            }
                          />
                          Default Brand Page
                        </label>
                      </div>
                      <div>
                        <label
                          htmlFor="Embed"
                          className="radioInput fontSize12"
                        >
                          <input
                            type="radio"
                            id="Embed"
                            value="embed_code"
                            name="default_landingPage"
                            onChange={(e) => {
                              // getWorkFLowURL();
                              handleStepUserOnboard(e);
                            }}
                            disabled={
                              steps?.apply_now_from_landingPage?.is_selected &&
                              !def_wf
                                ? false
                                : true
                            }
                            defaultChecked={
                              JSON.parse(ele?.content[0]?.steps)
                                ?.apply_now_from_landingPage?.embed_code
                            }
                          />
                          Embed 'Start Application' URL in lender Brand Page
                        </label>

                        {steps?.apply_now_from_landingPage?.embed_code ? (
                          <div className="copytoclip-cont">
                            <div className="display_Flex spaceBetween alignItem_center">
                              <div>
                                <input type="text" value={embedUrl} disabled />
                              </div>
                              <div>
                                <img
                                  src={copyIcon}
                                  alt=""
                                  onClick={() => {
                                    navigator.clipboard.writeText(embedUrl);
                                    setToolTipHover(false);
                                    setToolTipSuccess(true);
                                  }}
                                  onMouseOver={() => setToolTipHover(true)}
                                  onMouseOut={() => {
                                    setToolTipHover(false);
                                    setToolTipSuccess(false);
                                  }}
                                />
                                <div>
                                  {tooltipHover ? (
                                    <div className="disTooltip">
                                      Copy to clipboard
                                    </div>
                                  ) : tooltipSuccess ? (
                                    <div className="disTooltip">Copied!</div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mainFrame">
                    <label className="goldenGlow_Frame">
                      <div>
                        <p className="nero_text fontSize12 fontWeight500">
                          Invite from Lender Portal
                        </p>
                      </div>

                      <div className="accordionCheckbox ">
                        <input
                          disabled={def_wf}
                          onChange={(e) => handleStepUserOnboard(e)}
                          type="checkbox"
                          id="Invite_lenderPortal"
                          name="user_onboarding"
                          value={'Invite_lenderPortal'}
                          defaultChecked={
                            JSON.parse(ele?.content[0]?.steps)
                              ?.invite_from_lender_portal?.is_selected
                          }
                        />

                        <label htmlFor="Invite_lenderPortal"></label>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="itemCol">
                  <div className="actionFrame  ">
                    <div className="  actionFrameTitle spaceBetween">
                      <div className="display_Flex alignItem_center">
                        <h3 className="fontSize20 fontWeight500 blackGlaze_text">
                          Actions
                        </h3>
                        <p className="steelWool_text fontSize12 pt5">
                          You can select or deselect Onboarding Actions here.
                        </p>
                      </div>
                      <div>
                        <button
                          className="blackGlaze_primarybtnClr MainBtn"
                          onClick={() => {
                            setNoAction((prev) => {
                              return {
                                index: i,
                                action: ele.section,
                                subAction: '',
                                status:
                                  ele.section !== prev.action
                                    ? true
                                    : !prev.status,
                              };
                            });
                          }}
                        >
                          Add Action
                        </button>

                        {noAction.action === ele.section &&
                        noAction.index === i &&
                        noAction.status ? (
                          <div className="addaction_dropdown">No Actions</div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="p20">
                      {ele?.content?.map((el) =>
                        el.actions
                          ?.sort((a, b) => a.action_set_id - b.action_set_id)
                          ?.map((act, i) => (
                            <div className="action_Checkbox mb_4" key={i}>
                              <input
                                disabled={def_wf || act.action_set_id === 1}
                                name={`${el.service_name}_${act.action_id}`}
                                value={act.action_id}
                                type="checkbox"
                                defaultChecked={act.is_selected}
                                id={`${el.service_name}_${act.action_set_id}`}
                                onChange={(e) =>
                                  handleActionsUseronboard(
                                    e,
                                    el.service_id,
                                    el.frame_id,
                                    act.action_id,
                                    act.action_set_id
                                  )
                                }
                              />

                              <label
                                htmlFor={`${el.service_name}_${act.action_set_id}`}
                                className="customLabelrightm16"
                              >
                                {act?.action}
                              </label>
                            </div>
                          ))
                      )}
                    </div>

                    {/* <div className="display_Flex alignItem_center gap16 mb24">
                      <div >
                        <img src={AddCheckbox} alt="addCheckbox" className="cursorPointer" />
                        <img src={RemoveCheckbox} alt="removeCheckbox" className="cursorPointer" />
                        </div>
                      <div className="fontSize12 fontWeight500 steelWool_text blackGlaze_text">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      </div>
                    </div> */}

                    {/* <Modal
                      show={addActionshow}
                      onHide={addActionhandleClose}
                      centered
                      className="addAction"
                    >
                      <Modal.Header closeButton>
                        <h2 className="fontSize16 fontWeight500 blackGlaze_text">
                          Add Action
                        </h2>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="mb24 positionRelative">
                          <input
                            className="InputselectDropdown"
                            placeholder="--Select--"
                            onClick={() => setLog(!log)}
                          />
                          {log && (
                            <div className="searchResults">
                              <div className="searchResultsItem">
                                <label className="radioInput fontSize12">
                                  <input type="radio" id="Default_lp" />
                                  {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}
                                  Successful {">"} Account Creation
                                </label>

                                <label className="radioInput fontSize12">
                                  <input type="radio" id="Default_lp" />
                                  {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}
                                  Successful {">"} Account Creation
                                </label>
                              </div>

                              <div className="searchResultsItem">
                                <label className="radioInput fontSize12">
                                  <input type="radio" id="Default_lp" />
                                  {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}
                                  Successful {">"} Account Creation
                                </label>

                                <label className="radioInput fontSize12">
                                  <input type="radio" id="Default_lp" />
                                  {"<"}Email {">"} {"<"}Borrower {">"}On {"<"}
                                  Successful {">"} Account Creation
                                </label>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="display_Flex alignItem_center gap12 justifyContentEnd">
                          <div>
                            <button className="MainBtn goldenGlow_secondarybtnClr">
                              Cancel
                            </button>
                          </div>
                          <div>
                            <button className="MainBtn goldenGlow_primarybtnClr">
                              ok
                            </button>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal> */}
                  </div>
                </div>
                {/* temp div */}
              </div>
            ) : (
              <>
                <div className="StepFrame borderConnectBottom pt20px">
                  <div className="display_Flex gap32 right_dashedBorder">
                    <div className="square">{ele.order_no}</div>
                    <div>
                      <h2 className="fontSize20 fontWeight600 blackGlaze_text">
                        {ele.section}
                      </h2>
                      <p className="steelWool_text fontSize12 fontWeight500">
                        Choose options here
                      </p>
                    </div>
                  </div>
                </div>
                {ele?.content
                  ?.sort((a, b) => a.service_id - b.service_id)
                  ?.map((element, i, arr) => (
                    <div
                      className={`StepFrame gap22 pt20px ${
                        arr.length - 1 === i ? '' : 'borderConnectBottom'
                      }`}
                      key={i}
                    >
                      <div className="display_Flex gap32 itemCol second_dashedBorder">
                        <div className="width_40"></div>
                        <div>
                          <h2 className="fontSize18 fontWeight500 blackGlaze_text">
                            {element.frame_name}
                          </h2>
                          {element?.frame_name && (
                            <p
                              style={
                                element.active_flag
                                  ? { opacity: 1 }
                                  : { opacity: 0.3 }
                              }
                              className="steelWool_text fontSize12 fontWeight500 mb24"
                            >
                              Choose options here
                            </p>
                          )}

                          <div className="display_Flex alignItem_center gap10">
                            {/* <div>
                              <button className="MainBtn blackGlaze_secondarybtnClr">
                                <img src={Screenfull} alt="Screenfull" />
                                <span>Preview</span>
                              </button>
                            </div> */}
                            <div>
                              {/* <button disabled={def_wf}
                                id="enableStep"
                                className="MainBtn blackGlaze_primarybtnClr width150"
                                onClick={() =>
                                  handleDisableStep(
                                    element.wf_id,
                                    element.service_id
                                  )
                                }
                              >
                                {element.active_flag
                                  ? "Disable this Step"
                                  : "Enable this Step"}
                              </button> */}

                              {/* <Modal
                              show={dsblstepshow}
                              onHide={DsblstephandleClose}
                              centered
                              className="smallpopup"
                            >
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <h5 className="fontSize14 fontWeight500 blackGlaze_text mb24">
                                  Are You Sure ?? You want to Disable this Step{" "}
                                  <span className="goldenGlow_text">
                                    {" "}
                                    (User Identity Verification)
                                  </span>{" "}
                                  ?
                                </h5>

                                <div className="display_Flex alignItem_center gap12 justifyContentEnd">
                                  <div>
                                    <button className="MainBtn goldenGlow_secondarybtnClr">
                                      Cancel
                                    </button>
                                  </div>
                                  <div>
                                    <button className="MainBtn goldenGlow_primarybtnClr">
                                      ok
                                    </button>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal> */}
                            </div>
                          </div>
                        </div>
                        <div className="display_Flex gap10">
                          {/* Enable/disable toggle btn */}
                          <div>
                            <label
                              className="custom_switch"
                              data-cooltipz-dir="top"
                              aria-label={
                                element.active_flag
                                  ? 'Disable this Step'
                                  : 'Enable this Step'
                              }
                            >
                              <input
                                disabled={def_wf}
                                checked={element.active_flag}
                                className="toggle_checkbox"
                                type="checkbox"
                              />
                              <div
                                className="toggle_switch"
                                onClick={() =>
                                  enableToggleBtn && handleDisableStep(
                                    element.wf_id,
                                    element.service_id,
                                    element.frame_name
                                  )
                                }
                              ></div>
                            </label>
                          </div>
                          {/* Preview btn */}
                          {/* <div>
                            <button
                              className="emptyBtn"
                              data-cooltipz-dir="top"
                              aria-label="Preview"
                            >
                              <img src={Screenfull} alt="Screenfull" />
                            </button>
                          </div> */}
                        </div>
                      </div>

                      <div
                        className="itemCol"
                        style={
                          element.active_flag
                            ? { opacity: 1 }
                            : { opacity: 0.3 }
                        }
                      >
                        <div className="mainFrame">
                          <label className="goldenGlow_Frame">
                            <div>
                              <p className="nero_text fontSize12 fontWeight500">
                                {element?.service_name == 'Identity Verification'
                                  ? 'User Identity Verification'
                                  : element?.service_name ==
                                    'Employment Verfication'
                                  ? 'Employment Details Verification'
                                  : element?.service_name ==
                                    'Banking Verification'
                                  ? 'Bank Details Verification'
                                  : element?.service_name == 'Credit pull'
                                  ? 'Credit Verification'
                                  : element?.service_name == 'Approval'
                                  ? 'Approval'
                                  : element?.service_name == 'Repayment'
                                  ? 'Repayment'
                                  : ''}
                              </p>
                            </div>

                            {/* <div className="accordionCheckbox">
                              <input
                                disabled={!element.active_flag}
                                defaultChecked
                                type="checkbox"
                                id="userIdentityVerification"
                              />

                              <label htmlFor="userIdentityVerification"></label>
                            </div> */}
                          </label>

                          <div style={{display: 'grid', gridAutoFlow: 'column', gridTemplateRows: 'auto auto auto', gridColumnGap: "20px"}} className="p20">
                            {element?.apps
                              ?.sort((a, b) => a.app_order_no - b.app_order_no)
                              ?.map(
                                (app, i) =>
                                  app.logo ? (
                                    <div
                                      className="inputCheckbox mb_20"
                                      key={i}
                                      // style={
                                      //   app.active_flag
                                      //     ? { opacity: 1 }
                                      //     : { opacity: 0.3 }
                                      // }
                                    >
                                      <input
                                        disabled={
                                          // !app.active_flag ||
                                          !element.active_flag ||
                                          def_wf
                                        }
                                        checked={app?.is_selected && element.active_flag}
                                        // defaultChecked={app?.is_selected && element.active_flag}
                                        type="checkbox"
                                        id={`${element.frame_name}_${app.app_id}`}
                                        name={element?.service_name}
                                        onChange={(e) =>
                                          enableToggleBtn && handleApps(
                                            e,
                                            element.service_id,
                                            element.frame_id,
                                            app.app_id,
                                            element.wf_id,
                                            element.service_name,
                                            element.order_no,
                                            element.frame_name
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={`${element.frame_name}_${app.app_id}`}
                                      >
                                        <img
                                          src={`${
                                            process.env.REACT_APP_APIURL
                                          }/workflow/view/${encodeURIComponent(
                                            app.logo
                                          )}`}
                                        />
                                      </label>
                                    </div>
                                  ) : <></>
                              )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="itemCol overflowX actionFrame"
                        style={
                          element.active_flag
                            ? { opacity: 1 }
                            : { opacity: 0.3 }
                        }
                      >
                        <div className="">
                          <div className="  actionFrameTitle spaceBetween  ">
                            <div className="display_Flex alignItem_center">
                              <h3 className="fontSize20 fontWeight500 blackGlaze_text">
                                Actions
                              </h3>
                              <p className="steelWool_text fontSize12 pt5">
                                You can select or deselect Onboarding Actions
                                here.
                              </p>
                            </div>

                            <div>
                              <button
                                disabled={!element.active_flag}
                                className="blackGlaze_primarybtnClr MainBtn"
                                onClick={() => {
                                  setNoAction((prev) => {
                                    return {
                                      index: i,
                                      action: ele.section,
                                      subActionInd:
                                        ele.section === 'KYC' ? i : null,
                                      status:
                                        ele.section === 'KYC'
                                          ? prev.subActionInd === i
                                            ? !prev.status
                                            : true
                                          : ele.section !== prev.action
                                          ? true
                                          : !prev.status,
                                    };
                                  });
                                  console.log('Prev: ', ele, i);
                                }}
                              >
                                Add Action
                              </button>

                              {ele.section === 'KYC' &&
                              noAction.action === ele.section &&
                              noAction.subActionInd === i &&
                              noAction.status ? (
                                <div className="addaction_dropdown">
                                  No Actions
                                </div>
                              ) : ele.section !== 'KYC' &&
                                noAction.action === ele.section &&
                                noAction.index === i &&
                                noAction.status ? (
                                <div className="addaction_dropdown">
                                  No Actions
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>

                          <div className="p20">
                            <div className="display_Flex  gap12">
                              <div className="flexEqualcol">
                                {element?.actions
                                  ?.sort(
                                    (a, b) => a.action_set_id - b.action_set_id
                                  )
                                  ?.map(
                                    (acts, i) =>
                                      acts.on_failure_proceed && (
                                        <div
                                          className="display_Flex alignItem_center gap16 mb16"
                                          key={i}
                                        >
                                          <div>
                                          {actionCheckbox[
                                            `action_checkbox_${acts.action_set_id}_${element.service_name}`
                                          ] === false ? (
                                            <img
                                              src={AddCheckbox}
                                              alt="addCheckbox"
                                              className="cursorPointer"
                                            />
                                          ) : (
                                            <img
                                              src={RemoveCheckbox}
                                              onClick={() => {
                                                if (acts.action_set_id === 1) {
                                                  return;
                                                }
                                                const newActions = {
                                                  ...actions,
                                                };
                                                const newActionCheckbox = {
                                                  ...actionCheckbox,
                                                };

                                                actions[ele?.section][
                                                  element.service_name
                                                ].map((data, i) => {
                                                  if (
                                                    acts.action_set_id ===
                                                    data.action_set_id
                                                  ) {
                                                    newActions[ele?.section][
                                                      element.service_name
                                                    ][i].checked = false;
                                                  }
                                                });
                                                newActionCheckbox[
                                                  `action_checkbox_${acts.action_set_id}_${element.service_name}`
                                                ] = false;
                                                setActions(newActions);
                                                setActionCheckbox(
                                                  newActionCheckbox
                                                );
                                                removeAction(
                                                  element.service_id,
                                                  acts.action_set_id
                                                );
                                                setJustDisableStep(false);
                                                /* if (
                                                newActionCheckbox[
                                                  `action_checkbox_${acts.action_set_id}_${element.service_name}`
                                                ] === false
                                              ) {
                                                return;
                                              } else {
                                                
                                              } */
                                              }}
                                              alt="removeCheckbox"
                                              className="cursorPointer"
                                            />
                                          )}
                                        </div>

                                          <div className="flexEqualcol">
                                            <label
                                              htmlFor={`${element.frame_name}_${acts.action_id}`}
                                              className="radioInput fontSize12"
                                            >
                                              <input
                                                value={acts.action_id}
                                                onChange={(e) => {
                                                  enableToggleBtn && handleActions(
                                                    e,
                                                    element.service_id,
                                                    element.frame_id,
                                                    acts.action_id,
                                                    acts.action_set_id
                                                  );

                                                  const newActionCheckbox = {
                                                    ...actionCheckbox,
                                                  };

                                                  newActionCheckbox[
                                                    `action_checkbox_${acts.action_set_id}_${element.service_name}`
                                                  ] = true;

                                                  const newActions = {
                                                    ...actions,
                                                  };
                                                  actions[ele?.section][
                                                    element.service_name
                                                  ].map((data, i) => {
                                                    if (
                                                      acts.action_set_id ===
                                                      data.action_set_id
                                                    ) {
                                                      if (
                                                        acts.action_id ===
                                                        data.action_id
                                                      ) {
                                                        newActions[
                                                          ele?.section
                                                        ][element.service_name][
                                                          i
                                                        ].checked = true;
                                                      } else {
                                                        newActions[
                                                          ele?.section
                                                        ][element.service_name][
                                                          i
                                                        ].checked = false;
                                                      }
                                                    }
                                                  });
                                                  setActions(newActions);
                                                  setActionCheckbox(
                                                    newActionCheckbox
                                                  );
                                                }}
                                                checked={
                                                  actions[ele?.section][
                                                    element?.service_name
                                                  ]?.filter(
                                                    (data) =>
                                                      acts.action_id ===
                                                      data.action_id
                                                  )[0].checked
                                                }
                                                disabled={
                                                  actions[ele?.section][
                                                    element.service_name
                                                  ]?.filter(
                                                    (data) =>
                                                      acts.action_id ===
                                                      data.action_id
                                                  )[0].disabled
                                                }
                                                type={'radio'}
                                                id={`${element.frame_name}_${acts.action_id}`}
                                                name={`${element.service_name}_${acts.action_set_id}`}
                                                defaultChecked={
                                                  acts.is_selected
                                                }
                                              />
                                              {acts.action}
                                            </label>
                                          </div>
                                        </div>
                                      )
                                  )}
                              </div>
                              <div className="flexEqualcol">
                                {element?.actions
                                  ?.sort(
                                    (a, b) => a.action_set_id - b.action_set_id
                                  )
                                  ?.map(
                                    (acts, i) =>
                                      acts.on_failure_proceed === false && (
                                        <div
                                          className="display_Flex alignItem_center gap16 mb16"
                                          key={i}
                                        >
                                          <div className="flexEqualcol">
                                            <label
                                              htmlFor={`${element.frame_name}_${acts.action_id}`}
                                              className="radioInput fontSize12"
                                            >
                                              <input
                                                value={acts.action_id}
                                                onChange={(e) => {
                                                  enableToggleBtn && handleActions(
                                                    e,
                                                    element.service_id,
                                                    element.frame_id,
                                                    acts.action_id,
                                                    acts.action_set_id
                                                  );

                                                  const newActionCheckbox = {
                                                    ...actionCheckbox,
                                                  };

                                                  newActionCheckbox[
                                                    `action_checkbox_${acts.action_set_id}_${element.service_name}`
                                                  ] = true;

                                                  const newActions = {
                                                    ...actions,
                                                  };
                                                  actions[ele?.section][
                                                    element.service_name
                                                  ].map((data, i) => {
                                                    if (
                                                      acts.action_set_id ===
                                                      data.action_set_id
                                                    ) {
                                                      if (
                                                        acts.action_id ===
                                                        data.action_id
                                                      ) {
                                                        newActions[
                                                          ele?.section
                                                        ][element.service_name][
                                                          i
                                                        ].checked = true;
                                                      } else {
                                                        newActions[
                                                          ele?.section
                                                        ][element.service_name][
                                                          i
                                                        ].checked = false;
                                                      }
                                                    }
                                                  });
                                                  setActions(newActions);
                                                  setActionCheckbox(
                                                    newActionCheckbox
                                                  );
                                                }}
                                                checked={
                                                  actions[ele?.section][
                                                    element.service_name
                                                  ]?.filter(
                                                    (data) =>
                                                      acts.action_id ===
                                                      data.action_id
                                                  )[0].checked
                                                }
                                                disabled={
                                                  actions[ele?.section][
                                                    element.service_name
                                                  ]?.filter(
                                                    (data) =>
                                                      acts.action_id ===
                                                      data.action_id
                                                  )[0].disabled
                                                }
                                                type={'radio'}
                                                id={`${element.frame_name}_${acts.action_id}`}
                                                name={`${element.service_name}_${acts.action_set_id}`}
                                                defaultChecked={
                                                  acts.is_selected
                                                }
                                              />
                                              {acts.action}
                                            </label>
                                          </div>
                                        </div>
                                      )
                                  )}
                              </div>
                            </div>

                            {element?.actions
                              ?.sort(
                                (a, b) => a.action_set_id - b.action_set_id
                              )
                              ?.map(
                                (acts, i) =>
                                  acts.on_failure_proceed === null &&
                                  acts.action !== null && (
                                    <div
                                      className="display_Flex alignItem_center gap16 mb16"
                                      key={i}
                                    >
                                      <div>
                                      {actionCheckbox[
                                        `action_checkbox_${acts.action_set_id}_${element.service_name}`
                                      ] === false ? (
                                        <img
                                          src={AddCheckbox}
                                          alt="addCheckbox"
                                          className="cursorPointer"
                                        />
                                      ) : (
                                        <img
                                          src={RemoveCheckbox}
                                          onClick={() => {
                                            if (acts.action_set_id === 1) {
                                              return;
                                            }
                                            const newActions = {
                                              ...actions,
                                            };
                                            const newActionCheckbox = {
                                              ...actionCheckbox,
                                            };

                                            actions[ele?.section][
                                              element.service_name
                                            ].map((data, i) => {
                                              if (
                                                acts.action_set_id ===
                                                data.action_set_id
                                              ) {
                                                newActions[ele?.section][
                                                  element.service_name
                                                ][i].checked = false;
                                              }
                                            });
                                            newActionCheckbox[
                                              `action_checkbox_${acts.action_set_id}_${element.service_name}`
                                            ] = false;
                                            setActions(newActions);
                                            setActionCheckbox(
                                              newActionCheckbox
                                            );
                                            removeAction(
                                              element.service_id,
                                              acts.action_set_id
                                            );
                                            setJustDisableStep(false);
                                            /* if (
                                                newActionCheckbox[
                                                  `action_checkbox_${acts.action_set_id}_${element.service_name}`
                                                ] === false
                                              ) {
                                                return;
                                              } else {
                                                
                                              } */
                                          }}
                                          alt="removeCheckbox"
                                          className="cursorPointer"
                                        />
                                      )}
                                    </div>

                                      <div className="flexEqualcol">
                                        <label
                                          htmlFor={`${element.frame_name}_${acts.action_id}`}
                                          className="radioInput fontSize12"
                                        >
                                          <input
                                            value={acts.action_id}
                                            onChange={(e) => {
                                              enableToggleBtn && handleActions(
                                                e,
                                                element.service_id,
                                                element.frame_id,
                                                acts.action_id,
                                                acts.action_set_id
                                              );

                                              const newActionCheckbox = {
                                                ...actionCheckbox,
                                              };

                                              newActionCheckbox[
                                                `action_checkbox_${acts.action_set_id}_${element.service_name}`
                                              ] = true;

                                              const newActions = {
                                                ...actions,
                                              };
                                              actions[ele?.section][
                                                element.service_name
                                              ].map((data, i) => {
                                                if (
                                                  acts.action_set_id ===
                                                  data.action_set_id
                                                ) {
                                                  if (
                                                    acts.action_id ===
                                                    data.action_id
                                                  ) {
                                                    newActions[ele?.section][
                                                      element.service_name
                                                    ][i].checked = true;
                                                  } else {
                                                    newActions[ele?.section][
                                                      element.service_name
                                                    ][i].checked = false;
                                                  }
                                                }
                                              });
                                              setActions(newActions);
                                              setActionCheckbox(
                                                newActionCheckbox
                                              );
                                            }}
                                            checked={
                                              actions[ele?.section][
                                                element.service_name
                                              ]?.filter(
                                                (data) =>
                                                  acts.action_id ===
                                                  data.action_id
                                              )[0].checked
                                            }
                                            disabled={
                                              actions[ele?.section][
                                                element.service_name
                                              ]?.filter(
                                                (data) =>
                                                  acts.action_id ===
                                                  data.action_id
                                              )[0].disabled
                                            }
                                            type={'radio'}
                                            id={`${element.frame_name}_${acts.action_id}`}
                                            name={`${element.service_name}_${acts.action_set_id}`}
                                            defaultChecked={acts.is_selected}
                                          />
                                          {acts.action}
                                        </label>
                                      </div>
                                    </div>
                                  )
                              )}
                          </div>
                        </div>
                      </div>

                      {/* <div className="itemCol"></div> */}
                    </div>
                  ))}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
      {/* </div> */}
    </>
  );
};

export default Workflow;
